import React, { useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ListIcon from "@mui/icons-material/List";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, useNavigate } from "react-router-dom";
import "./sidebar.css";
import { ReactComponent as ScreenIcon } from "../../Assets/images/screenIcon.svg";
import { ReactComponent as LogoutIcon } from "../../Assets/images/logoutIcon.svg";
import { logout } from "../../Redux/Actions/userAction";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import apkFile from "../../Assets/apk/crisax.screens_1.0.6.apk";
import AndroidIcon from "@mui/icons-material/Android";
const useStyle = makeStyles((theme) => {
  return {
    text: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    sibarContainerMobile: {
      [theme.breakpoints.down("sm")]: {
        width: "70px !important",
      },
    },
    sidebarUpperSecMobile: {
      [theme.breakpoints.down("sm")]: {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        width: "70px !important",
        height: "20vh",
        "& img": {
          width: "80px !important",
          height: "80px !important",
        },
      },
    },
  };
});
function Sidebar({ user }) {
  const classes = useStyle();
  const [gridView, setGridView] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    return navigate("/login");
  };
  return (
    <>
      <div className={`sidebar-container ${classes.sibarContainerMobile}`}>
        <div className={`sidebar-upper-sec ${classes.sidebarUpperSecMobile}`}>
          <img src="/images/crisax-screen-main-logo.png" />
        </div>
        <div className="sidebar-middle-sec">
          <div className="side-bar-box">
            <div className="links">
              <div className="link-icon">
                <DashboardIcon className="link-a-color" />
              </div>
              <Link
                className={classes.text}
                style={{
                  background: !gridView ? "#F3F0FF" : "white",
                }}
                onClick={() => setGridView(false)}
                to="/"
              >
                Dashboard
              </Link>
            </div>

            <div className="links">
              <div className="link-icon">
                <TextSnippetIcon className="link-a-color" />
              </div>
              <Link
                className={classes.text}
                style={{
                  background: !gridView ? "#F3F0FF" : "white",
                }}
                onClick={() => setGridView(false)}
                to="/content"
              >
                Content
              </Link>
            </div>
            {user.role === "admin" && (
              <div className="links">
                <div className="link-icon">
                  <PersonAddIcon className="link-a-color" />
                </div>
                <Link
                  className={classes.text}
                  style={{
                    background: !gridView ? "#F3F0FF" : "white",
                  }}
                  onClick={() => setGridView(false)}
                  to="/user"
                >
                  User
                </Link>
              </div>
            )}
            <div className="links">
              <div className="link-icon">
                <ScreenIcon
                  className="link-a-color"
                  style={{ fill: "#0d0d0d" }}
                />
              </div>
              <Link
                className={classes.text}
                style={{
                  background: !gridView ? "#F3F0FF" : "white",
                }}
                onClick={() => setGridView(false)}
                to="/screen"
              >
                Screen
              </Link>
            </div>

            <div className="links">
              <div className="link-icon">
                <ListIcon
                  className="link-a-color"
                  style={{ fill: "#0d0d0d" }}
                />
              </div>
              <Link
                className={classes.text}
                style={{
                  background: !gridView ? "#F3F0FF" : "white",
                }}
                onClick={() => setGridView(false)}
                to="/playlist"
              >
                Playlist
              </Link>
            </div>

            <div className="links">
              <div className="link-icon">
                <AndroidIcon
                  className="link-a-color"
                  style={{ fill: "#0d0d0d" }}
                />
              </div>
              <a href={apkFile}>Apk File</a>
            </div>

            <div className="links">
              <div className="link-icon">
                <LogoutIcon
                  className="link-a-color"
                  style={{ fill: "your-color" }}
                />
              </div>
              <button
                className={classes.text}
                style={{
                  border: "none",
                  outline: "none",
                  background: !gridView ? "#F3F0FF" : "white",
                  cursor: "pointer",
                  color: "#B8B8B8",
                }}
                onClick={logoutHandler}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
        <div className="sidebar-bottom-sec">
          <div className="settings">
            <SettingsIcon style={{ color: "white" }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
